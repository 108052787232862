import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import SimpleHeroSection from '../components/molecules/SimpleHeroSection';
import NewsletterSection from '../components/organisms/NewsletterSection';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="mb-32">
      <SimpleHeroSection>
        <div className="container flex flex-col items-center justify-center pt-16">
          <h1 className="u-h2">404: Not Found</h1>
          <p className="u-p">You just hit a route that doesn&#39;t exist.</p>
        </div>
      </SimpleHeroSection>
    </div>

    <NewsletterSection />
  </Layout>
);

export default NotFoundPage;
